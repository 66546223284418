import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const LayoutWrapper = styled.div``;

const pageData = {
  header: "Kontakt",
  mainTitle: "JB multimedia",
  subtitle: "Skontaktuj się z nami",
};

const ContactPage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);

  return (
    <>
      <div className={`contact-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>

      <Layout>
        {/* <div className="layoutWrapper"> */}

        <section className="contact-page">
          <LayoutWrapper>
            <Seo title="Kontakt" />
            <div className="contact-page-container">
              <div className="contact-page-container-wrapper">
                <div className="txt">
                  <div className="txt-container">
                    <h2>
                      Oddziały <br /> <strong>Sprzedaż detaliczna</strong>
                    </h2>
                  </div>
                  <div className="txt-wrapper detail">
                    <div>
                      <h4>Biała Podlaska</h4>
                      <p>ul. Brzeska 107A</p>
                      <p>21-500 Biała Podlaska</p>
                      <p>Tel: +48 83 344 70 70</p>
                      <p>e-mail: salon@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Przemyśl</h4>
                      <p>ul. Mikołaja Kopernika 11</p>
                      <p>37-700 Przemyśl</p>
                      <p>Tel: +48 833 446 298</p>
                      <p>e-mail: przemysl@jbmultimedia.pl</p>
                    </div>
                  </div>
                </div>
                <div className="txt">
                  <div className="txt-container">
                    <h2>
                      Biała Podlaska <br /> <strong>Sprzedaż hurtowa</strong>
                    </h2>
                  </div>
                  <div className="txt-wrapper">
                    <div>
                      <h4>Sprzedaż Hurtowa</h4>
                      <p>Tel.: + 48 83 344 70 84</p>
                      <p>e-mail: hurt@jbmultimedia.pl</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-page-container-wrapper">
                <div className="txt office">
                  <div className="txt-container">
                    <h2>
                      Biała Podlaska <br /> <strong>Biuro</strong>
                    </h2>
                  </div>
                  <div className="txt-wrapper">
                    <div>
                      <h4>Dział Księgowości</h4>
                      <p>Tel.: + 48 83 344 70 86</p>
                      <p>e-mail: finanse@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Dział Kadr</h4>
                      <p>Tel.: + 48 83 344 70 73</p>
                      <p>e-mail: kadry@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Dostawa i zaopatrzenie</h4>
                      <p>Tel.: + 48 83 344 70 97</p>
                      <p>e-mail: zaopatrzenie@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Dział Marketingu</h4>
                      <p>Tel.: + 48 83 344 70 04</p>
                      <p>e-mail: marketing@jbmultimedia.pl</p>
                    </div>
                  </div>
                  <div className="txt-wrapper">
                    <div>
                      <h4>Magazyn</h4>
                      <p>Tel: + 48 83 344 70 03</p>
                      <p>e-mail: magazyn@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Usługi logistyczne</h4>
                      <p>Tel: + 48 83 344 70 82</p>
                      <p>e-mail: logistyka@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Dział Biznesu</h4>
                      <p>Tel: + 48 83 344 70 02</p>
                      <p>e-mail: biznes@jbmultimedia.pl</p>
                    </div>
                    <div>
                      <h4>Dział IT</h4>
                      <p>Tel: + 48 83 344 70 96</p>
                      <p>e-mail: it@jbmultimedia.pl</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-page-container-wrapper">
                <div className="txt">
                  <div className="txt-container">
                    <h2>
                      Wsparcie techniczne <br />{" "}
                      <strong>Dzierżawa drukarek</strong>
                    </h2>
                  </div>
                  <div className="txt-wrapper">
                    <div>
                      <h4>Drukarki</h4>
                      <p>Tel.: + 48 661 801 808</p>
                      <p>e-mail: klik@jbmultimedia.pl</p>
                    </div>
                  </div>
                </div>
                <div className="txt">
                  <div className="txt-container">
                    <h2>
                      Wsparcie techniczne <br />
                      <strong>Serwis</strong>{" "}
                      <span>(komputery, drukarki, ekspresy, agd)</span>
                    </h2>
                  </div>
                  <div className="txt-wrapper">
                    <div>
                      <h4>Komputery</h4>
                      <p>Tel.: + 48 83 344 70 85</p>
                      <p>e-mail: serwis@jbmultimedia.pl</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapper>
        </section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.2617089604073!2d23.145866976969558!3d52.0385462717628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4721912af90b78bd%3A0x5aa74295570f6cb3!2sBrzeska%20107a%2C%2021-500%20Bia%C5%82a%20Podlaska!5e0!3m2!1spl!2spl!4v1688566967369!5m2!1spl!2spl"
          width="600"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        {/* </div> */}

        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo title="Kontakt" description="Chętnie odpowiemy na Twoje pytania" />
);

export default ContactPage;
